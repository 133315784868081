import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { paymentPeriod } from './constants';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateRandom12DigitValue(): string {
  // const min = 100000000000; // 10^11
  // const max = 999999999999; // 10^12 - 1

  // const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
  // return randomValue.toString();
  const randomPart = Math.floor(Math.random() * 1000000000)
    .toString()
    .padStart(10, '0');
  return `00${randomPart}`;
}

export function prependAmountZeroes(str: string): string {
  const length = str.length;
  if (length >= 12) {
    return str;
  }

  const numberOfZeroes = 12 - length;
  const zeroes = '0'.repeat(numberOfZeroes);

  return zeroes + str;
}

export function getMonthsByValue(targetValue: string): string | null {
  const selectedMonth = paymentPeriod.find(
    (period) => period.value === targetValue,
  );

  return selectedMonth ? selectedMonth.label : null;
}

export const stripPlusFromPhone = (phone: string): string => {
  return phone.startsWith('+') ? phone.slice(1) : phone;
};

export const modifyPhoneNumber = (phone: string): string => {
  const stripped = phone.slice(1);
  return `233${stripped}`;
};
