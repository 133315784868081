import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from 'react-router-dom';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './index.css'
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './utilities/queryClient.ts';
import Root from './routes/root.tsx';
// import Home from './routes/home.tsx';
import About from './routes/about.tsx';
import Dues from './routes/dues.tsx';
import RootError from './routes/error.tsx';
import * as Sentry from "@sentry/react"
import { Toaster } from "@/components/ui/sonner";

Sentry.init({
  dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /https:\/\/staging-live--capable-bubblegum-2be9a1\.netlify.app/, /https:\/\/ndcpayment\.com/],

  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0

})

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <RootError />,
    children: [
      {
        path: '/',
        element: <Dues />
      },
      {
        path: 'about',
        element: <About />
      },
      // {
      //   path: 'about',
      //   element: <About />
      // },
      // {
      //   path: 'dues',
      //   element: <Dues />
      // }
    ]
  }
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster
        expand={true}
        toastOptions={{
          unstyled: true,
          classNames: {
            toast: 'flex items-center space-x-3',
            error: 'text-ndcred bg-white rounded-md py-3 pr-5 pl-3 text-sm',
            title: 'font-semibold',
            success: 'bg-white text-ndcgreen rounded-md py-3 pr-5 pl-3 text-sm'
          }
        }}
      />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </React.StrictMode>,
)
