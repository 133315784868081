import { Outlet } from "react-router-dom";

export default function Root() {
  return (
    <div className="flex min-h-screen flex-col">
      <div id="content" className="flex">
        <Outlet />
      </div>
    </div>
  )
}