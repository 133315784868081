import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { groupPaymentSchema, idSchema, validateIDSchema } from '@/lib/schemas';
import { z } from 'zod';
import { getMonthsByValue, modifyPhoneNumber } from '@/lib/utils';
import { useFormStore, useValidUserStore } from '@/stores/rootStore';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import {
  useCheckBalance,
  useFetchUserDetails,
  useGetUserExists,
  useValidateUser,
  verifyRecaptcha,
} from '@/services/dues';
import { CreditCard, Loader, ShieldCheck, Smartphone } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { useDebouncedCallback } from 'use-debounce';
import { paymentPeriod } from '@/lib/constants';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';
import ReCAPTCHA from 'react-google-recaptcha';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { toast } from 'sonner';

export default function GroupPaymentForm({
  submitAction,
  submitPending,
}: {
  submitAction: (values: z.infer<typeof groupPaymentSchema>) => void;
  submitPending: boolean;
}) {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [fieldsDisabled, setFieldsDisabled] = useState(true)
  const [idNotExist, setIdNotExist] = useState(false)
  const [payerIdValid, setPayerIdValid] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  // const [payerIdValidLength, setPayerIdValidLength] = useState(false)
  const { updateMonthsToPay, setPaymentType } = useFormStore((state) => state);
  const { updateBalanceAvailability } = useValidUserStore((state) => state);
  const [insufficientFunds, setInsufficientFunds] = useState(false);
  const {
    setGroupMemberData,
    selectedMembers,
    setSelectedMembers,
    resetSelectedMembers,
  } = useValidUserStore((state) => state);
  const { mutate: fetchUserMutate, isPending: fetchUserPending } =
    useFetchUserDetails();
  const { mutate: getUserExistMutate, isPending: userExistPending } =
    useGetUserExists();
  const { mutate: validateUserMutate, isPending: validateUserPending } =
    useValidateUser();
  const { mutate: confirmBalanceMutation, isPending: confirmBalancePending } =
    useCheckBalance();
  const initialGroupPmtValues = useMemo(() => {
    return {
      phonenumber: '',
      id: '',
      confirmId: '',
      amount: '',
      member_ids: ''
    };
  }, []);
  const groupPaymentForm = useForm<z.infer<typeof groupPaymentSchema>>({
    resolver: zodResolver(groupPaymentSchema),
    defaultValues: initialGroupPmtValues,
  });
  const watchMemberId = groupPaymentForm.watch('member_ids') ?? '';
  const watchAmount = groupPaymentForm.watch('amount');
  const formId = groupPaymentForm.watch('id')
  const formConfirmId = groupPaymentForm.watch('confirmId')

  const resetGroupForm = () => {
    groupPaymentForm.reset(initialGroupPmtValues);
    resetSelectedMembers();
    setGroupMemberData(undefined);
    setFieldsDisabled(true)
  };

  const submitGroupPmt = async (values: z.infer<typeof groupPaymentSchema>) => {
    setVerifyLoading(true)
    try {
      const captchaValue = await recaptchaRef?.current?.executeAsync();
      if (!captchaValue) {
        toast.error('Error', {
          description: 'ReCaptcha is invalid. Please try again'
        })
      } else {
        const data = await verifyRecaptcha(captchaValue)
        if (data.success) {
          setVerifyLoading(false)
          confirmBalanceMutation(values.id, {
            onSuccess: (response) => {
              setPaymentType('group');
              const selectedMonths = getMonthsByValue(values.amount);
              const actualAmount = selectedMembers
                ? parseInt(values.amount) * selectedMembers.length
                : parseInt(values.amount);
              updateMonthsToPay(selectedMonths);
              updateBalanceAvailability(response.balance >= actualAmount ? true : false);
              values.phonenumber = modifyPhoneNumber(values.phonenumber)
              submitAction(values);
            },
          });
        } else {
          
          toast.error('Error', {
            description: 'ReCaptcha is invalid. Please try again'
          })
          groupPaymentForm.reset(initialGroupPmtValues);
        }
      }
    } catch (e) {
      console.error(e)
      toast.error('Error', {
        description: 'We could not validate your request. Please try again'
      })
    } finally {
      setVerifyLoading(false)
      resetGroupForm()
      recaptchaRef.current?.reset()
    }
  };

  const verifyDonorID = useDebouncedCallback((ids: { id: string, confirmid: string }) => {
    const validateIDs = validateIDSchema.safeParse(ids)
    if (!validateIDs.success) {
      if (validateIDs.error.issues.length === 1) {
        groupPaymentForm.setError('confirmId', {
          message: validateIDs.error.issues[0].message
        })
      }
    } else {
      groupPaymentForm.setError('confirmId', {
        message: ''
      })
      fetchUserMutate(validateIDs.data.id, {
        onSuccess: (data) => {
          setGroupMemberData(data);
          setFieldsDisabled(false)
        },
        onError: () => {
          setPayerIdValid(true);
        },
      });
    }
  }, 500);

  const handleMemberAdd = useDebouncedCallback((id: string) => {
    const validateMemberId = idSchema.safeParse(id)
    if (!validateMemberId.success) {
      if (validateMemberId.error.issues.length === 1) {
        groupPaymentForm.setError('member_ids', {
          message: validateMemberId.error.issues[0].message
        })
      }
    } else {
      groupPaymentForm.setError('member_ids', {
        message: ''
      })
      getUserExistMutate(id, {
        onSuccess: (data) => {
          if (data.exists) {
            validateUserMutate(id, {
              onSuccess: () => {
                setSelectedMembers(id);
              },
              onSettled: () => {
                groupPaymentForm.resetField('member_ids');
                groupPaymentForm.setError('member_ids', {
                  message: ''
                })
              },
            });
          } else {
            setIdNotExist(true);
          }
        },
      });
    } 
  }, 800);

  useEffect(() => {
    if (watchMemberId.length > 0) handleMemberAdd(watchMemberId);
  }, [handleMemberAdd, watchMemberId]);


  useEffect(() => {
    const subscription = groupPaymentForm.watch((_value, { name }) => {
      if (name === 'rswitch' || name === 'phonenumber') {
        void groupPaymentForm.trigger(['phonenumber']);
      }
    });
    // Cleanup the subscription on unmount.
    return () => subscription.unsubscribe();
  }, [groupPaymentForm])

  useEffect(() => {
    verifyDonorID({ id: formId, confirmid: formConfirmId })
  }, [formId, formConfirmId, verifyDonorID])


  return (
    <div className={`flex h-full w-full flex-col lg:w-full lg:items-start lg:justify-start text-zinc-400`}>
      <div className={`flex w-full flex-col lg:items-start lg:justify-start`}>

        <Form {...groupPaymentForm}>
          <form
            onSubmit={groupPaymentForm.handleSubmit(submitGroupPmt)}
            className="mx-auto mt-4 flex w-full flex-col px-2 sm:w-11/12"
            onChange={() => {
              setInsufficientFunds(false);
              setPayerIdValid(false);
              // setPayerIdValidLength(false)
              setIdNotExist(false);
            }}
          >
            <div className="my-2 flex w-full flex-col">
              <FormLabel className="mb-4 text-sm text-zinc-600 font-light">Please provide your Member ID. We will verify it before proceeding</FormLabel>
              <div className="flex flex-col space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 sm:flex-row">
                <div className="basis-full sm:basis-1/2">
                  <FormField
                    name="id"
                    control={groupPaymentForm.control}
                    render={({ field }) => (
                      <FormItem className="grow space-y-0">
                        <FormControl>
                          <Input
                            {...field}
                            disabled={!fieldsDisabled}
                            type="text"
                            placeholder="Member ID"
                            className="w-full placeholder:text-xs text-xs py-2 text-zinc-500 "
                          />
                        </FormControl>
                        <FormMessage className="mt-0" />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="basis-full sm:basis-1/2">
                  <FormField
                    name='confirmId'
                    control={groupPaymentForm.control}
                    render={({ field }) => (
                      <FormItem className="w-full relative">
                        {/* <FormLabel>Confirm Member ID</FormLabel> */}
                        <FormControl>
                          <Input
                            {...field}
                            disabled={!fieldsDisabled}
                            type="text"
                            placeholder="Confirm Member ID"
                            className="w-full placeholder:text-xs text-xs py-2 text-zinc-500 "
                          />
                        </FormControl>
                        {fetchUserPending && <Loader size={16} className="animate-spin absolute top-1 right-2" />}
                        <FormMessage className="mt-0" />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              {payerIdValid && (
                <p className="text-ndc-red-alt mt-2 text-xs">
                  We could not verify your ID. Please check and try again
                </p>
              )}
            </div>
            {insufficientFunds && (
              <p className="text-ndc-red-alt p-0 text-xs">
                You do not have sufficient funds to make this payment. Please
                top up your balance and try again
              </p>
            )}
            <div className="mt-2 flex flex-col space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 sm:flex-row">
              <div className="basis-full sm:basis-1/2">
                <FormField
                  name='rswitch'
                  control={groupPaymentForm.control}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          value={field.value}
                          disabled={fieldsDisabled}
                        >
                          <SelectTrigger className='w-full rounded-md px-2 py-3 text-xs text-zinc-500'>
                            <SelectValue placeholder="Select a Network" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem className="text-xs" value="MTN">MTN</SelectItem>
                            <SelectItem className="text-xs" value="VDF">Vodafone</SelectItem>
                            <SelectItem className="text-xs" value="ATL">Airtel/Tigo</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="mt-0" />
                    </FormItem>
                  )}
                />
              </div>
              <div className="basis-full sm:basis-1/2">
                <FormField
                  control={groupPaymentForm.control}
                  name='phonenumber'
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Input
                          {...field}
                          disabled={fieldsDisabled}
                          placeholder="Your phone number"
                          className="w-full placeholder:text-xs text-xs py-2 text-zinc-500"
                        />
                      </FormControl>
                      <FormMessage className="mt-0" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="mt-2 flex flex-col space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 sm:flex-row">
              <div className="basis-full sm:basis-1/2">
                <FormField
                  control={groupPaymentForm.control}
                  name='amount'
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                          disabled={fieldsDisabled}
                        >
                          <SelectTrigger className='w-full rounded-md px-2 py-3 text-xs text-zinc-500'>
                            <SelectValue placeholder="Select Payment Period" />
                          </SelectTrigger>
                          <SelectContent>
                            {paymentPeriod.map((item, idx) => (
                              <SelectItem key={idx} value={item.value}>{item.label}</SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="mt-0" />
                    </FormItem>
                  )}
                />
              </div>
              <div className="basis-full sm:basis-1/2">
                <FormField
                  name="member_ids"
                  control={groupPaymentForm.control}
                  render={({ field }) => (
                    <FormItem className="relative grow">
                      <FormControl>
                        <Input
                          {...field}
                          disabled={
                            fieldsDisabled ||
                            userExistPending ||
                            validateUserPending ||
                            selectedMembers?.length === 20
                          }
                          type="text"
                          placeholder="Enter a member's ID"
                          className="w-full placeholder:text-xs text-xs py-2 text-zinc-500"
                        />
                      </FormControl>
                      {userExistPending ||
                        (validateUserPending && (
                          <Loader
                            className="absolute right-4 top-1 animate-spin text-zinc-700"
                            size={16}
                          />
                        ))}
                      <FormDescription className="text-ndc-red-alt text-xs">

                        {/* {idInvalid && <>ID invalid. Please check and try again</>} */}
                        {groupPaymentForm.formState.errors.member_ids && (
                          <>
                            {
                              groupPaymentForm.formState.errors.member_ids
                                .message
                            }
                          </>
                        )}
                        {idNotExist && <>Please ensure you have entered a valid ID</>}
                      </FormDescription>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            {selectedMembers && (
              <>
                <p className="text-right text-xs text-zinc-700">
                  Members added: {selectedMembers.length} / 20
                </p>
                <ScrollArea className="mb-2 mt-1 flex h-52 w-full flex-col rounded-lg border bg-zinc-200 p-4 ">
                  <p className="text-sm font-semibold text-zinc-700 underline">
                    Payment Details
                  </p>
                  <ul className="mt-1">
                    <li className="flex flex-row items-center justify-between text-sm text-zinc-700">
                      <span className="mr-4 w-20 font-light">
                        Members:{' '}
                      </span>
                      <span className="grow text-right font-semibold">
                        {selectedMembers.join(', ')}
                      </span>
                    </li>
                    <Separator />
                    <li className="flex flex-row items-center justify-between py-1 text-sm text-zinc-700">
                      <span className="w-28 font-light">
                        Months to Pay:
                      </span>
                      <span className="grow text-right font-semibold">
                        {getMonthsByValue(watchAmount)}
                      </span>
                    </li>
                    <Separator />
                    <li className="flex flex-row items-center justify-between py-1 text-sm text-zinc-700">
                      <span className="w-28 font-light">
                        Amount to Pay:{' '}
                      </span>
                      <span className="grow text-right font-semibold">
                        GHS {parseInt(watchAmount) * selectedMembers.length}
                        {'.00'}
                      </span>
                    </li>
                    <Separator />
                  </ul>
                  <p className="mt-3 text-xs text-zinc-900">
                    * By clicking the <code>Make Payment</code> button you
                    confirm the listed details and will be directed to the
                    payment page.
                  </p>
                </ScrollArea>
              </>
            )}
            <div className="mt-1 flex flex-col">
              <div className="basis-full flex flex-col rounded-md border border-input bg-background px-3 py-2">
                <FormLabel className="text-xs text-zinc-400 mb-2">Select a Payment Option</FormLabel>
                <FormField
                  control={groupPaymentForm.control}
                  name='paymentmethod'
                  render={({ field }) => (
                    <FormItem className="space-y-3">
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          disabled={fieldsDisabled}
                        >
                          <FormItem className="flex items-center justify-between space-y-0">
                            <div className="items-center flex space-x-1">
                              <FormControl className="">
                                <RadioGroupItem value='momo' />
                              </FormControl>
                              <FormLabel className="text-xs text-zinc-500">Mobile Money</FormLabel>
                            </div>
                            <Smartphone size={20} />
                          </FormItem>
                          <FormItem className="flex items-center justify-between space-y-0">
                            <div className="items-center flex space-x-1">
                              <FormControl>
                                <RadioGroupItem value='card' />
                              </FormControl>
                              <FormLabel className="text-xs text-zinc-500">Card</FormLabel>
                            </div>
                            <CreditCard size={20} />
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage className="mt-0" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2 sm:items-center mt-2">
              <Button variant='outline' className="basis-full" type='reset' onClick={resetGroupForm}>Cancel</Button>
              <Button disabled={fieldsDisabled} className="basis-full bg-ndcgreen-alt h-auto flex-row items-center space-x-3 text-zinc-100" type='submit'>
                {verifyLoading || submitPending || confirmBalancePending ? (<Loader size={16} className="animate-spin" />) : (
                  <>
                    <span>Make Payment</span>
                    <ShieldCheck size={16} />
                  </>
                )}
              </Button>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={import.meta.env.VITE_RECAPTCHA}
              size='invisible'
              className='mx-auto'
            />
          </form>
        </Form>
      </div>
    </div>
  );
}
