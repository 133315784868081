import { request } from "@/utilities/core_request"
import { useMutation } from "@tanstack/react-query"

type RegisterPayload = {
 id: string
 phonenumber: string
}

const registerMember = async (payload: RegisterPayload) => {
  const response = await request(`${import.meta.env.VITE_API_URL}/user/register`, {
    body: payload
  })

  return response
}

export const useRegisterMember = () => {
  return useMutation({
    mutationKey: ['register_member'],
    mutationFn: (values: RegisterPayload) => registerMember(values)
  })
}

