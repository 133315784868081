import { groupPaymentSchema, selfPaymentSchema } from '@/lib/schemas';
import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { z } from 'zod';

export type PersistGroupTrx = {
  from: string;
  to: string;
  unitamount: number;
};

export type PaymentType = 'self' | 'group' | undefined;

export type SelfFormState = {
  formData: z.infer<typeof selfPaymentSchema> | undefined;
  groupData: z.infer<typeof groupPaymentSchema> | undefined;
  persistGroupPayload: PersistGroupTrx | undefined;
  monthsToPay: string | null;
  paymentType: PaymentType;
  _hasHydrated: boolean;
};

export type SelfFormActions = {
  updateFormData: (values: z.infer<typeof selfPaymentSchema>) => void;
  updateGroupData: (values: z.infer<typeof groupPaymentSchema>) => void;
  updateMonthsToPay: (value: string | null) => void;
  persistGroupTrxData: (data: PersistGroupTrx | undefined) => void;
  resetFormData: () => void;
  setPaymentType: (value: PaymentType) => void;
  setHasHydrated: (value: boolean) => void;
};

export const initialSelfFormState: SelfFormState = {
  formData: undefined,
  _hasHydrated: false,
  monthsToPay: null,
  groupData: undefined,
  persistGroupPayload: undefined,
  paymentType: undefined,
};

export const createSelfPmtFormSlice: StateCreator<
  SelfFormState & SelfFormActions,
  [['zustand/immer', unknown]],
  [['zustand/immer', unknown]],
  SelfFormState & SelfFormActions
> = immer((set) => ({
  ...initialSelfFormState,
  updateFormData: (values: z.infer<typeof selfPaymentSchema>) =>
    set((state) => {
      state.formData = values;
    }),
  updateMonthsToPay: (data: string | null) =>
    set((state) => {
      state.monthsToPay = data;
    }),
  updateGroupData: (data: z.infer<typeof groupPaymentSchema>) =>
    set((state) => {
      state.groupData = data;
    }),
  resetFormData: () => set(initialSelfFormState),
  persistGroupTrxData: (data: PersistGroupTrx | undefined) =>
    set((state) => {
      state.persistGroupPayload = data;
    }),
  setHasHydrated: (value: boolean) =>
    set((state) => {
      state._hasHydrated = value;
    }),
  setPaymentType: (value: PaymentType) =>
    set((state) => {
      state.paymentType = value;
    }),
}));
