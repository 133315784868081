import { StateCreator } from "zustand"
import { immer } from "zustand/middleware/immer"

export type CheckoutResponse = {
  status: string | undefined;
  code: number | undefined;
  reason: string | undefined;
  token: string | undefined;
  checkout_url: string | undefined;
  _hasHydrated?: boolean;
};


export type CheckoutResponseActions = {
  updateCheckoutResponse: (data: CheckoutResponse) => void
  resetCheckoutResponse: () => void
  setHasHydrated: (value: boolean) => void
}



export const initialCheckoutResponseData: CheckoutResponse = {
  status: undefined,
  reason: undefined,
  code: undefined,
  token: undefined,
  checkout_url: undefined,
  _hasHydrated: false
}


export const createCheckoutSlice: StateCreator<
  CheckoutResponse & CheckoutResponseActions,
  [['zustand/immer', unknown]],
  [['zustand/immer', unknown]],
  CheckoutResponse & CheckoutResponseActions
> = immer((set) => ({
  ...initialCheckoutResponseData,
  updateCheckoutResponse: (data: CheckoutResponse) => set((state) => {
    state.checkout_url = data.checkout_url
    state.code = data.code
    state.reason = data.reason
    state.token = data.token
    state.status = data.status
  }),
  resetCheckoutResponse: () => set(initialCheckoutResponseData),
  setHasHydrated: (value: boolean) => set((state) => { state._hasHydrated = value })
}));
