import { FetchUserResponse } from '@/lib/constants';
import { ValidUserType } from '@/services/dues';
import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export type ValidUserState = {
  isValidId: boolean;
  idExists: boolean;
  validUserData: ValidUserType | undefined;
  groupMemberData: FetchUserResponse | undefined;
  selectedMembers: Array<string> | undefined;
  balanceAvailability: boolean;
  paymentTrxState: boolean;
  _hasHydrated: boolean;
};

export type ValidUserActions = {
  setValidUser: (data: ValidUserType | undefined) => void;
  setIsValidID: (value: boolean) => void;
  setIdExists: (value: boolean) => void;
  setPmtTrxState: (value: boolean) => void;
  setHasHydrated: (value: boolean) => void;
  setGroupMemberData: (data: FetchUserResponse | undefined) => void;
  updateBalanceAvailability: (value: boolean) => void;
  setSelectedMembers: (value: string) => void;
  resetSelectedMembers: () => void;
  resetVaildUserData: () => void;
};

const initialState: ValidUserState = {
  isValidId: false,
  idExists: false,
  validUserData: undefined,
  balanceAvailability: false,
  paymentTrxState: false,
  _hasHydrated: false,
  groupMemberData: undefined,
  selectedMembers: undefined,
};

export const createValidUserSlice: StateCreator<
  ValidUserState & ValidUserActions,
  [['zustand/immer', unknown]],
  [['zustand/immer', unknown]],
  ValidUserState & ValidUserActions
> = immer((set) => ({
  ...initialState,
  setValidUser: (data: ValidUserType | undefined) =>
    set((state) => {
      state.validUserData = data;
    }),
  setIsValidID: (data: boolean) =>
    set((state) => {
      state.isValidId = data;
    }),
  setIdExists: (data: boolean) =>
    set((state) => {
      state.idExists = data;
    }),
  setPmtTrxState: (data: boolean) =>
    set((state) => {
      state.paymentTrxState = data;
    }),
  setSelectedMembers: (value: string) =>
    set((state) => {
      if (state.selectedMembers === undefined) {
        state.selectedMembers = [value];
      }
      if (state.selectedMembers && state.selectedMembers.includes(value)) {
        state.selectedMembers = [...state.selectedMembers];
      }
      if (state.selectedMembers && !state.selectedMembers.includes(value)) {
        state.selectedMembers = [...state.selectedMembers, value];
      }
    }),
  setHasHydrated: (data: boolean) =>
    set((state) => {
      state._hasHydrated = data;
    }),
  setGroupMemberData: (data: FetchUserResponse | undefined) =>
    set((state) => {
      state.groupMemberData = data;
    }),
  resetVaildUserData: () => set(initialState),
  resetSelectedMembers: () =>
    set((state) => {
      state.selectedMembers = undefined;
    }),
  updateBalanceAvailability: (value: boolean) =>
    set((state) => {
      state.balanceAvailability = value;
    }),
}));
