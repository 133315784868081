import { ErrorResponse, Link, useNavigate } from "react-router-dom";
import { Button } from "../ui/button";

export default function ErrorWidget({ errorInfo }: { errorInfo?: ErrorResponse }) {
  const navigate = useNavigate()
  
  return (
    <div className="error-bg flex flex-col w-screen h-screen justify-start pt-20 items-start px-4 lg:px-16">
      <div className="w-16 h-16">
        <img src="/logo_main.png" className="w-full h-auto" alt="NDC Dues Payment Platform" />
      </div>

      <div className="mt-auto">
        <h1 className="text-7xl text-zinc-100 leading-loose font-semibold">Oops</h1>
        <p className="text-xl text-zinc-100 font-semibold leading-loose">{errorInfo?.statusText ?? 'Something Went Wrong'}</p>
      </div>

      <div className="mt-auto">
        <h1 className="text-9xl font-semibold text-zinc-100 leading-loose">{errorInfo?.status ?? '500'}</h1>
        <Button onClick={() => navigate("/")}>
          Take me back
        </Button>
      </div>
      <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mt-auto pb-10">
        <div className="mb-2 md:mb-0">
          <p className="text-xs text-zinc-100">&copy;{new Date().getFullYear()} NDC Payments. All rights reserved</p>
        </div>
        <div className="flex flex-row items-center justify-between md:space-x-4 w-full md:w-auto">
          <Link
            to="/"
            className="text-xs text-zinc-100"
          >
            Privacy Policy
          </Link>
          <Link
            to="/"
            className="text-xs text-zinc-100"
          >
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  )
}