import {
  FetchUserResponse,
  PayswitchInitiate,
  PayswitchInitiateResponse,
} from '@/lib/constants';
import { request } from '@/utilities/core_request';
import { useMutation, useQuery } from '@tanstack/react-query';

type DataReturn = {
  branch: string;
  code: string;
  constituency: string;
  region: string;
  electoralarea: string;
};

export type PaymentPayload = {
  amount: number;
  transactionid: string;
  rswitch: string;
  memberid: string;
  subscribernumber: string;
  otp: number;
  paymentmethod: string;
  desc: string;
};

type RecaptchaResponse = {
  challenge_ts: string;
  hostname: string;
  success: boolean;
  'error-codes'?: Array<string>;
};

type CheckoutResponse = {
  status: string;
  code: number;
  reason: string;
  token: string;
  checkout_url: string;
};

type TrxStatus = {
  code: string;
  status: string;
  reason: string;
  transaction_id: string;
  r_switch: string;
  subscriber_number: string;
  amount: number;
};
export type Services = 'dues' | 'ggc';

export type BalanceAvailability = {
  memberid: string;
  balance: number;
  createdon: EpochTimeStamp;
  updatedon: EpochTimeStamp;
};

export type PersistTrx = {
  id: string;
  memberid: string;
  amount: number;
  succeeded: boolean;
};

export interface ValidUserType {
  readonly data: DataReturn;
  readonly valid: boolean;
}

interface UserExists {
  readonly exists: boolean;
}

/**
 *
 * @param memberid
 * @returns UserExists
 * Get the status of the provided memberid
 */
const getUserExistStatus = async (memberid: string) => {
  const response = await request<UserExists>(
    `${import.meta.env.VITE_API_URL}/user/exist`,
    {
      body: { id: memberid },
    },
  );
  return response;
};

/**
 * @param id
 * @param phonenumber
 * @returns ok
 * register non-existent user
 */
const registerUser = async ({ id, phone }: { id: string; phone: string }) => {
  const response = await request(
    `${import.meta.env.VITE_API_URL}/user/register`,
    {
      method: 'POST',
      body: {
        id: id,
        phonenumber: phone,
      },
    },
  );
  return response;
};

/**
 *
 * @param memberid
 * @returns
 * Confirm that the ID provided is associated with a branch
 */

const validateUser = async (memberid: string) => {
  // const response = await request<ValidUserType>(
  const response = await request(
    `${import.meta.env.VITE_API_URL}/id/validate`,
    {
      body: { id: memberid },
    },
  );

  return response;
};

/**
 *
 * @param payload
 * @returns
 * Once payment via Payment gateway is complete and successful
 * trigger to persist transaction to DB
 */
const persistTransaction = async (payload: PersistTrx) => {
  const response = await request(
    `${import.meta.env.VITE_API_URL}/transaction/persist`,
    {
      body: payload,
    },
  );

  return response;
};

/**
 *
 * @param payload
 * @returns
 * Initiate payment with payswitch
 */
const initiatePayswitch = async (payload: PayswitchInitiate) => {
  // const response = await request<PayswitchInitiateResponse>(
  //   `${import.meta.env.VITE_PAYSWITCH_CHECKOUT_URL}/initiate`,
  //   {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Basic ${payswitchAuthString}`,
  //       'Cache-Control': 'no-cache',
  //       'Content-Type': 'application/json',
  //     },
  //     body: payload,
  //   },
  // );

  const response = await request<PayswitchInitiateResponse>(
    `${import.meta.env.VITE_API_URL}/checkout`,
    {
      method: 'POST',
      body: payload,
    },
  );

  return response;
};

const fetchUserData = async (userid: string) => {
  const response = await request<FetchUserResponse>(
    `${import.meta.env.VITE_API_URL}/user`,
    {
      body: { id: userid },
    },
  );

  return response;
};

const sendOTP = async ({
  phone,
  service,
}: {
  phone: string;
  service: Services;
}) => {
  const response = await request(`${import.meta.env.VITE_OTP_URL}/otp`, {
    method: 'POST',
    body: { phonenumber: phone, service: service },
  });

  return response;
};

const verifyOTP = async ({
  phone,
  service,
  code,
}: {
  phone: string;
  service: Services;
  code: number;
}) => {
  const response = await request(`${import.meta.env.VITE_OTP_URL}/otp/verify`, {
    method: 'POST',
    body: {
      phonenumber: phone,
      service: service,
      otp: code,
    },
  });
  return response;
};

const sendBulkPayments = async ({
  from,
  to,
  unitamount,
}: {
  from: string;
  to: string;
  unitamount: number;
}) => {
  const response = await request(`${import.meta.env.VITE_API_URL}/bulk/dues`, {
    method: 'POST',
    body: {
      from: from,
      to: to,
      unitamount: unitamount,
    },
  });
  return response;
};

const confirmBalanceAvailability = async (id: string) => {
  const response = await request<BalanceAvailability>(
    `${import.meta.env.VITE_API_URL}/account`,
    {
      method: 'POST',
      body: {
        id: id,
      },
    },
  );
  return response;
};

const verifyTransaction = async (trxId: string | undefined) => {
  // if (trxId === undefined) return false;
  const response = await request<TrxStatus>(
    `${
      import.meta.env.VITE_PAYSWITCH_API_URL
    }/v1.1/users/transactions/${trxId}/status`,
    {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Merchant-Id': `${import.meta.env.VITE_PAYSWITCH_MERCHANT_ID}`,
      },
    },
  );
  return response;
};

const makePayment = async (payload: PaymentPayload) => {
  const response = await request<CheckoutResponse | null>(
    `${import.meta.env.VITE_API_URL}/payment`,
    {
      method: 'POST',
      body: payload,
    },
  );
  return response;
};

export const verifyRecaptcha = async (
  captchaValue: string | null | undefined,
) => {
  const response = await request<RecaptchaResponse>(
    `${import.meta.env.VITE_API_URL}/recaptcha/verify`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: { captchaValue },
    },
  );
  return response;
};

export const useMakePayment = () => {
  return useMutation({
    mutationKey: ['make_payment'],
    mutationFn: (values: PaymentPayload) => makePayment(values),
  });
};

export const useRegisterUser = () => {
  return useMutation({
    mutationKey: ['register_user'],
    mutationFn: ({ id, phone }: { id: string; phone: string }) =>
      registerUser({ id, phone }),
  });
};

export const useGetUserExists = () => {
  return useMutation({
    mutationKey: ['userExists'],
    mutationFn: (id: string) => getUserExistStatus(id),
  });
};

export const useValidateUser = () => {
  return useMutation({
    mutationKey: ['validateUser'],
    mutationFn: (id: string) => validateUser(id),
  });
};

export const useInitiatePayment = () => {
  return useMutation({
    mutationKey: ['initiate_payment'],
    mutationFn: (payload: PayswitchInitiate) => initiatePayswitch(payload),
  });
};

export const usePersistTransaction = () => {
  return useMutation({
    mutationKey: ['persist_transaction'],
    mutationFn: (values: PersistTrx) => persistTransaction(values),
  });
};

export const useFetchUserDetails = () => {
  return useMutation({
    mutationKey: ['fetch_user'],
    mutationFn: (userid: string) => fetchUserData(userid),
  });
};

export const useSendOTP = () => {
  return useMutation({
    mutationKey: ['send_otp'],
    mutationFn: ({ phone, service }: { phone: string; service: Services }) =>
      sendOTP({ phone, service }),
  });
};

export const useVerifyOTP = () => {
  return useMutation({
    mutationKey: ['verify_otp'],
    mutationFn: ({
      phone,
      service,
      code,
    }: {
      phone: string;
      service: Services;
      code: number;
    }) => verifyOTP({ phone, service, code }),
  });
};

export const useSendBulkPayments = () => {
  return useMutation({
    mutationKey: ['send_bulk_payments'],
    mutationFn: ({
      from,
      to,
      unitamount,
    }: {
      from: string;
      to: string;
      unitamount: number;
    }) => sendBulkPayments({ from, to, unitamount }),
  });
};

export const useCheckBalance = () => {
  return useMutation({
    mutationKey: ['check_balance'],
    mutationFn: (id: string) => confirmBalanceAvailability(id),
  });
};

export const useVerifyTransaction = (trxid: string | undefined) => {
  return useQuery({
    queryKey: ['verify_transactions', trxid],
    queryFn: () => verifyTransaction(trxid),
    enabled: !!trxid,
  });
};

export const usePersistTrx = ({
  id,
  memberid,
  amount,
  succeeded,
}: {
  id: string;
  memberid: string;
  amount: number;
  succeeded: boolean;
}) => {
  return useQuery({
    queryKey: ['persist_trx', id, memberid, amount, succeeded],
    queryFn: () => persistTransaction({ id, memberid, amount, succeeded }),
    enabled: !!succeeded,
  });
};
