import { selfPaymentSchema, validateIDSchema } from "@/lib/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { z } from "zod";
import { Button } from "../ui/button";
import ReCAPTCHA from 'react-google-recaptcha';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { useEffect, useRef, useState } from "react";
import { paymentPeriod } from "@/lib/constants";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { CreditCard, Loader, ShieldCheck, Smartphone } from "lucide-react";
import { useDebouncedCallback } from "use-debounce";
import { useFormStore, useValidUserStore } from "@/stores/rootStore";
import { getMonthsByValue, modifyPhoneNumber } from "@/lib/utils";
import { useGetUserExists, useValidateUser, verifyRecaptcha } from "@/services/dues";
import { toast } from "sonner";

export default function SelfPaymentForm({
  pending,
  submitAction,
}: {
  pending: boolean
  submitAction: (v: z.infer<typeof selfPaymentSchema>) => void
}) {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [fieldsDisabled, setFieldsDisabled] = useState(true)
  const [captchaPending, setCaptchaPending] = useState(false)
  const { setPaymentType, updateMonthsToPay } = useFormStore((state) => state)
  const { setIdExists } = useValidUserStore(
    (state) => state,
  );
  const { mutate: getUserExistMutate, isPending: userExistPending } =
    useGetUserExists();
  const { mutate: validateUserMutate, isPending: validateUserPending } =
    useValidateUser();
  const pmtForm = useForm<z.infer<typeof selfPaymentSchema>>({
    resolver: zodResolver(selfPaymentSchema),
    defaultValues: {
      id: '',
      confirmId: '',
      phonenumber: ''
    }
  })
  const formMemberId = pmtForm.watch('id')
  const formConfirmId = pmtForm.watch('confirmId')

  const submitData = async (v: z.infer<typeof selfPaymentSchema>) => {
    setCaptchaPending(true)
    try {
      const captchaToken = await recaptchaRef.current?.executeAsync()
      if (!captchaToken) {
        toast.error('Error', {
          description: 'ReCaptcha is invalid. Please try again'
        })
      } else {
        const data = await verifyRecaptcha(captchaToken)
        if(data.success) {
          setPaymentType('self')
          const selectedMonth = getMonthsByValue(v.amount);
          updateMonthsToPay(selectedMonth);
          v.phonenumber = modifyPhoneNumber(v.phonenumber)
          submitAction(v)
        } else {
          toast.error('Error', {
            description: 'ReCaptcha is invalid. Please try again'
          })
        }
      }  
    } catch (e) {
      console.error(e)
      toast.error('Error', {
        description: 'We could not validate your request. Please try again'
      })
    } finally {
      setCaptchaPending(false)
      pmtForm.reset()
      recaptchaRef.current?.reset()
    }
    
    
    
  }

  const compareIDs = useDebouncedCallback((ids: { id: string, confirmid: string }) => {
    const validateIDs = validateIDSchema.safeParse(ids)
    if (!validateIDs.success) {
      if (validateIDs.error.issues.length === 1) {
        pmtForm.setError('confirmId', {
          message: validateIDs.error.issues[0].message,
        })
      }
    } else {
      pmtForm.setError('confirmId', {
        message: '',
      })
      getUserExistMutate(validateIDs.data.id, {
        onSuccess: (resp) => {
          setIdExists(resp.exists)
          validateUserMutate(validateIDs.data.id, {
            onSuccess: () => {
              setFieldsDisabled(false)
            },
            onError: () => {
              console.error('Error occured')
              pmtForm.setError('id', {
                message: 'ID is invalid'
              })
            }
          });
        },
        onError: (error) => {
          console.error('Error occurred', error)
          pmtForm.setError('id', {
            message: 'ID is invalid'
          })
        }
      })
      // setFieldsDisabled(false)
      //verify validity of member id

    }

  }, 500)

  const resetPmtForm = () => {
    pmtForm.reset({
      phonenumber: '',
      id: '',
      confirmId: '',
    })
    setFieldsDisabled(true)
  }

  useEffect(() => {
    const subscription = pmtForm.watch((_value, { name }) => {
      if (name === 'rswitch' || name === 'phonenumber') {
        void pmtForm.trigger(['phonenumber']);
      }

    });
    // Cleanup the subscription on unmount.
    return () => subscription.unsubscribe();
  }, [pmtForm])

  useEffect(() => {
    compareIDs({ id: formMemberId, confirmid: formConfirmId })
  }, [compareIDs, formConfirmId, formMemberId])

  return (
    <div className={`flex h-full w-full flex-col lg:w-full lg:items-start lg:justify-start text-zinc-400`}>
      <div className={`flex w-full flex-col lg:items-start lg:justify-start`}>
        <Form {...pmtForm}>
          <form className="mx-auto mt-4 flex w-full flex-col px-2 sm:w-11/12" onSubmit={pmtForm.handleSubmit(submitData)}>
            <div className="flex flex-col">
              <p className="mb-4 text-sm text-zinc-600 font-light">Please provide your Member ID. We will verify it before proceeding</p>
              <div className="flex flex-col space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 sm:flex-row">
                <div className="basis-full sm:basis-1/2">
                  <FormField
                    name='id'
                    control={pmtForm.control}
                    render={({ field }) => (
                      <FormItem className="w-full">
                        {/* <FormLabel>Member ID</FormLabel> */}
                        <FormControl>
                          <Input
                            {...field}
                            type="text"
                            placeholder="Member ID"
                            disabled={!fieldsDisabled || userExistPending || validateUserPending}
                            className="w-full placeholder:text-xs text-xs py-2 text-zinc-500 "
                          />
                        </FormControl>
                        <FormMessage className="mt-0" />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="basis-full sm:basis-1/2">
                  <FormField
                    name='confirmId'
                    control={pmtForm.control}
                    render={({ field }) => (
                      <FormItem className="w-full relative">
                        {/* <FormLabel>Confirm Member ID</FormLabel> */}
                        <FormControl>
                          <Input
                            {...field}
                            type="text"
                            disabled={!fieldsDisabled || userExistPending || validateUserPending}
                            placeholder="Confirm Member ID"
                            className="w-full placeholder:text-xs text-xs py-2 text-zinc-500 "
                          />
                        </FormControl>
                        {userExistPending || validateUserPending && <Loader size={16} className="animate-spin absolute top-1 right-2" />}
                        <FormMessage className="mt-0" />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2 flex flex-col space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 sm:flex-row">
              <div className="basis-full sm:basis-1/2">
                <FormField
                  name='rswitch'
                  control={pmtForm.control}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          value={field.value}
                          disabled={fieldsDisabled}
                        >
                          <SelectTrigger className='w-full rounded-md px-2 py-3 text-xs text-zinc-500'>
                            <SelectValue placeholder="Select a Network" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem className="text-xs" value="MTN">MTN</SelectItem>
                            <SelectItem className="text-xs" value="VDF">Vodafone</SelectItem>
                            <SelectItem className="text-xs" value="ATL">Airtel/Tigo</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="mt-0" />
                    </FormItem>
                  )}
                />
              </div>
              <div className="basis-full sm:basis-1/2">
                <FormField
                  control={pmtForm.control}
                  name='phonenumber'
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Input
                          {...field}
                          disabled={fieldsDisabled}
                          placeholder="Your phone number"
                          className="w-full placeholder:text-xs text-xs py-2 text-zinc-500"
                        />
                      </FormControl>
                      <FormMessage className="mt-0" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="mt-2 flex flex-row">
              <div className="basis-full">
                <FormField
                  control={pmtForm.control}
                  name='amount'
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                          disabled={fieldsDisabled}
                        >
                          <SelectTrigger className='w-full rounded-md px-2 py-3 text-xs text-zinc-500'>
                            <SelectValue placeholder="Select Payment Period" />
                          </SelectTrigger>
                          <SelectContent>
                            {paymentPeriod.map((item, idx) => (
                              <SelectItem key={idx} value={item.value}>{item.label}</SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="mt-0" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="mt-2 flex flex-col">
              <div className="basis-full flex flex-col rounded-md border border-input bg-background px-3 py-2">
                <p className="text-xs text-zinc-400 mb-2">Select a Payment Option</p>
                <FormField
                  control={pmtForm.control}
                  name='paymentmethod'
                  render={({ field }) => (
                    <FormItem className="space-y-3">
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          disabled={fieldsDisabled}
                        >
                          <FormItem className="flex items-center justify-between space-y-0">
                            <div className="items-center flex space-x-1">
                              <FormControl className="">
                                <RadioGroupItem value='momo' />
                              </FormControl>
                              <FormLabel className="text-xs text-zinc-500">Mobile Money</FormLabel>
                            </div>
                            <Smartphone size={20} />
                          </FormItem>
                          <FormItem className="flex items-center justify-between space-y-0">
                            <div className="items-center flex space-x-1">
                              <FormControl>
                                <RadioGroupItem value='card' />
                              </FormControl>
                              <FormLabel className="text-xs text-zinc-500">Card</FormLabel>
                            </div>
                            <CreditCard size={20} />
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage className="mt-0" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className='mt-2 mb-2 w-full flex flex-row'>
              {/* <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={import.meta.env.VITE_RECAPTCHA}
                size='invisible'
                className='mx-auto'
              /> */}
            </div>
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2 sm:items-center">
              <Button variant='outline' className="basis-full" type='reset' onClick={resetPmtForm}>Cancel</Button>
              <Button disabled={fieldsDisabled} className="basis-full bg-ndcgreen-alt h-auto flex-row items-center space-x-3 text-zinc-100" type='submit'>
                {pending ||captchaPending ? (<Loader size={16} className="animate-spin" />) : (
                  <>
                    <span>Make Payment</span>
                    <ShieldCheck size={16} />
                  </>
                )}
              </Button>
            </div>
            {/* <Button type="submit">
              Submit
            </Button> */}
            <ReCAPTCHA
              ref={recaptchaRef}
              size='invisible'
              sitekey={import.meta.env.VITE_RECAPTCHA}
            />
          </form>
        </Form>
      </div>
    </div>
  )
}