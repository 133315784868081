
import ErrorWidget from "@/components/global/ErrorWidget"
import { isRouteErrorResponse, useRouteError } from "react-router-dom"

export default function RootError() {
  const error = useRouteError()
  

  if (isRouteErrorResponse(error)) {
    return <ErrorWidget errorInfo={error} />
  }

  return <ErrorWidget />
}