import { Loader } from "lucide-react";
import { Button } from "../ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem } from "../ui/form";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { verifySchema } from "@/lib/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from "../ui/input-otp";

export default function OTPVerifyForm({ confirmOTP, pendingState, otpInvalid, setOtpInvalid }: { confirmOTP: (values: z.infer<typeof verifySchema>) => void, pendingState: boolean, otpInvalid: boolean, setOtpInvalid: (value: boolean) => void }) {
  const initialVerifyValues = useMemo(() => {
    return {
      otp: ""
    }
  }, [])
  const verifyForm = useForm<z.infer<typeof verifySchema>>({
    resolver: zodResolver(verifySchema),
    defaultValues: initialVerifyValues
  })

  return (
    <Form {...verifyForm}>
      <form
        onSubmit={verifyForm.handleSubmit(confirmOTP)}
        onChange={() => setOtpInvalid(false)}
        className="flex flex-col items-center"
      >
        <FormField
          name="otp"
          control={verifyForm.control}
          render={({ field }) => (
            <FormItem className='mb-4 w-full'>
              <FormControl>
                <InputOTP maxLength={6} {...field} className="ml-auto">
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                  </InputOTPGroup>
                  <InputOTPSeparator />
                  <InputOTPGroup>
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormDescription>Please enter the OTP sent to your phone</FormDescription>
              <FormDescription className='text-xs text-ndcred'>
                {verifyForm.formState.errors.otp && <span>{verifyForm.formState.errors.otp.message}</span>}
                {otpInvalid && <span>Please enter a valid OTP</span>}
              </FormDescription>
            </FormItem>
          )}
        />
        <Button
          disabled={pendingState}
          type='submit'
          className='w-full bg-ndcgreen hover:bg-white hover:ring hover:ring-ndcgreen hover:text-ndcgreen disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-60'
        >
          {pendingState ? <Loader size={16} className='animate-spin' /> : <span>Verify OTP</span>}
        </Button>
        {/* <AlertDialogAction type='submit' disabled={true} className='float-right mr-2'>Proceed</AlertDialogAction> */}
      </form>
    </Form>
  )
}