import { encode as base64_encode } from 'base-64';

type Currency = 'NGN' | 'GHS' | 'USD' | 'ZAR' | 'KES' | 'XOF';

export const trxCurr: Currency = 'GHS';
export const paymentPeriod = [
  {
    label: '3 months',
    value: '6',
  },
  {
    label: '6 months',
    value: '12',
  },
  {
    label: '12 months',
    value: '24',
  },
  {
    label: '24 months',
    value: '48',
  },
  {
    label: '48 months',
    value: '96',
  },
];

export interface PayswitchInitiate {
  transaction_id: string;
  desc: string;
  amount: string;
  email: string;
}

export interface PayswitchInitiateResponse {
  status: string;
  code: number;
  reason: string;
  token: string;
  checkout_url: string;
}

export interface FetchUserResponse {
  memberid: string;
  constituency: string;
  branch: string;
  region: string;
  phonenumber: string;
  electoralarea: string;
  createdon: EpochTimeStamp;
  updatedon: EpochTimeStamp;
}

export const payswitchAuthString = base64_encode(
  `${import.meta.env.VITE_PAYSWITCH_USERNAME}:${
    import.meta.env.VITE_PAYSWITCH_API_KEY
  }`,
);
