import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import {
  SelfFormActions,
  SelfFormState,
  createSelfPmtFormSlice,
} from './formSlice';
import {
  CheckoutResponse,
  CheckoutResponseActions,
  createCheckoutSlice,
} from './paymentSlice';
import {
  ValidUserActions,
  ValidUserState,
  createValidUserSlice,
} from './memberSlice';

export const useFormStore = create<SelfFormActions & SelfFormState>()(
  persist(
    (...a) => ({
      ...createSelfPmtFormSlice(...a),
    }),
    {
      name: 'selfForm-storage',
      storage: createJSONStorage(() => localStorage),
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    },
  ),
);

export const useCheckoutResponseStore = create<
  CheckoutResponse & CheckoutResponseActions
>()(
  persist(
    (...a) => ({
      ...createCheckoutSlice(...a),
    }),
    {
      name: 'checkout-storage',
      storage: createJSONStorage(() => localStorage),
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    },
  ),
);

export const useValidUserStore = create<ValidUserState & ValidUserActions>()(
  persist(
    (...a) => ({
      ...createValidUserSlice(...a),
    }),
    {
      name: 'member-storage',
      storage: createJSONStorage(() => localStorage),
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    },
  ),
);
